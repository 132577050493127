export const environment = {
    production: true,
    hmr: false,
    name: 'production',
    URL_GATEWAY: 'https://arrecadacao-api.sema.mt.gov.br',
    URL_BPMS: 'https://bpms.sema.mt.gov.br',
    URL_PORTAL: 'https://portal.sema.mt.gov.br',
    URL_LICENCIAMENTO: 'https://licenciamento.sema.mt.gov.br',
    URL_LICENCIAMENTO_API: 'https://licenciamento-api.sema.mt.gov.br',
    URL_PORTAL_API: 'https://portal-api.sema.mt.gov.br',
    URL_SCORP_API: 'https://scorp-api.sema.mt.gov.br',
    URL_GEO: '',
    URL_GEOPORTAL_BASE_REFERENCIA: 'https://basegeo-api.sema.mt.gov.br',
    URL_PANDORA: '',
    URL_CLOUD_SERVER_PORTAL: '',
    URL_CLOUD_SERVER_EUREKA: '',
    URL_WSO2_AUTH: '',
    URL_WSO2_USER_INFO: '',
    OAUTH_CLIENT_KEY: 'M4xK_8Jjhf0XVdB7cpBiP0JoZ00a',
    OAUTH_CLIENT_SECRET: 'aqEtaGeG0Yc5gh4SW0g2_OeEQYYa',
    OAUTH_SCOPES: 'email openid profile address photourl picture',
    UF_PADRAO: 'MT',
    IDENTITY_COOKIE_NAME: "br.gov.mt.sema.identity"
};