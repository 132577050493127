import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IErrorMessageObject, IMessageObject, MessageDialog, MessageDialogResult } from '../message-dialog/message-dialog.model';

@Component({
    selector: 'app-pdf-viewer-dialog',
    templateUrl: './pdf-viewer-dialog.component.html'
})
export class PdfViewerDialogComponent implements OnInit {
    source: Blob;

    constructor(
        public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private http: HttpClient,
        private messageDialog: MessageDialog
    ) {

    } 

    private fetch(): void {
        this.http
            .get(this.data.source, { params: this.data.httpParams, responseType: 'blob' })
            .pipe(catchError(this.showErrorDialog.bind(this)))
            .subscribe(blob => this.source = blob);
    }

    private loadPdf(): void {
        if (typeof this.data.source === 'string') {
            this.fetch();
        } else {
            this.source = this.data.source;
        }
    }

    private showErrorDialog(message: string | IMessageObject | IErrorMessageObject): Observable<MessageDialogResult> {
        return this.messageDialog.showError(message);
    }

    ngOnInit(): void {
        this.loadPdf();                
    }
}