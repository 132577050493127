import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FuseSharedModule} from "../../../@fuse/shared.module";
import {SharedModule} from "../../shared/shared.module";
import {ReemissaoTaxaModule} from './reemissao-de-taxa/reemissao-de-taxa.module';
import {DialogConfirmacaoComponent} from "./reaproveitamento-de-taxa/fases-do-reaproveitamento/dialog-confirmacao/dialog-confirmacao.component";
import {DialogConfirmacaoReceitaGrupoComponent} from "./receita-grupo/dialog-confirmacao-receita-grupo/dialog-confirmacao-receita-grupo.component";
import {DialogConfirmacaoReceitaNaturezaComponent} from "./receita-natureza/dialog-confirmacao-receita-natureza/dialog-confirmacao-receita-natureza.component";
import {DialogConfirmacaoSubGrupoAtividadesComponent} from "./sub-grupo-atividades/dialog-confirmacao-sub-grupo-atividades/dialog-confirmacao-sub-grupo-atividades.component";
import {DialogConfirmacaoAtividadesComponent} from "./atividades/dialog-confirmacao-atividades/dialog-confirmacao-atividades.component";
import {DialogConfirmacaoTipoTitulacaoComponent} from "./tipo-titulacao/dialog-confirmacao-tipo-titulacao/dialog-confirmacao-tipo-titulacao.component";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {getDutchPaginatorIntl} from "../../util/tradutor-paginator";
import { ImportacaoDarExternoComponent } from './importacao-dar-externo/importacao-dar-externo.component';

// Exemplo rota
const routes: Routes = [
    {
        path: 'emissao-taxa',
        loadChildren: './emissao-taxa/emissao-taxa.module#EmissaoTaxaModule',
    },
    {
        path: 'reaproveitamento-de-taxa',
        loadChildren: './reaproveitamento-de-taxa/reaproveitamento-de-taxa.module#ReaproveitamentoDeTaxaModule',
    },
    {
        path: 'manter-atividade-servico',
        loadChildren: './manter-atividade-servico/manter-atividade-servico.module#ManterAtividadeServicoModule',
    },
    {
        path: 'taxas-emitidas',
        loadChildren: './taxas-emitidas/taxas-emitidas.module#TaxasEmitidasModule'
    },
    {
        path: 'reemissao-de-taxa',
        loadChildren: './reemissao-de-taxa/reemissao-de-taxa.module#ReemissaoTaxaModule',
    },
    {
        path: 'ressarcimento-de-taxa',
        loadChildren: './ressarcimento-de-taxa/ressarcimento-de-taxa.module#RessarcimentoDeTaxaModule',
    },
    {
        path: 'extrato-financeiro',
        loadChildren: './extrato-financeiro/extrato-financeiro.module#ExtratoFinanceiroModule'
    },
    {
        path: 'receita-grupo',
        loadChildren: './receita-grupo/receita-grupo.module#ReceitaGrupoModule'
    },
    {
        path: 'receita-natureza',
        loadChildren: './receita-natureza/receita-natureza.module#ReceitaNaturezaModule'
    },
    {
        path: 'subgrupo-atividades',
        loadChildren: './sub-grupo-atividades/sub-grupo-atividades.module#SubGrupoAtividadesModule'
    },
    {
        path: 'atividades',
        loadChildren: './atividades/atividades.module#AtividadesModule'
    },
    {
        path: 'upf',
        loadChildren: './upf/upf.module#UpfModule'
    },
    {
        path: 'vincular-processo',
        loadChildren: './vincular-processo/vincular-processo.module#VincularProcessoModule'
    },
    {
        path: 'tipo-titulacao',
        loadChildren: './tipo-titulacao/tipo-titulacao.module#TipoTitulacaoModule'
    },
    {
        path: 'consultar-ressarcimento',
        loadChildren: './consultar-ressarcimento/consultar-ressarcimento.module#ConsultarRessarcimentoModule'
    },
    {
        path: 'sub-atividades',
        loadChildren: './sub-atividades/sub-atividades.module#SubAtividadesModule'
    },
    {
        path: 'modulo-fiscal',
        loadChildren: './modulos-fiscais/modulos-fiscais.module#ModulosFiscaisModule'
    },
    {
        path: 'importacao-dar-externo',
        loadChildren: './importacao-dar-externo/importacao-dar-externo.module#ImportacaoDarExternoModule'
    },
];

@NgModule({
    declarations: [
        DialogConfirmacaoComponent,
        DialogConfirmacaoReceitaGrupoComponent,
        DialogConfirmacaoReceitaNaturezaComponent,
        DialogConfirmacaoSubGrupoAtividadesComponent,
        DialogConfirmacaoAtividadesComponent,
        DialogConfirmacaoTipoTitulacaoComponent,
    ],
    imports: [
        // Angular Core
        CommonModule,
        RouterModule.forChild(routes),

        // Fuse
        FuseSharedModule,
        SharedModule,
        ReemissaoTaxaModule
    ],
    entryComponents: [
        DialogConfirmacaoComponent,
        DialogConfirmacaoReceitaGrupoComponent,
        DialogConfirmacaoReceitaNaturezaComponent,
        DialogConfirmacaoSubGrupoAtividadesComponent,
        DialogConfirmacaoAtividadesComponent,
        DialogConfirmacaoTipoTitulacaoComponent,],
    providers: [
        { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() }
    ]
})
export class PagesModule {
}
