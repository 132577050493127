import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { CoreMessageService } from './core-message.service';
import { genericRetryStrategy } from './generic-retry-strategy';
import { environment } from 'environments/environment';
import { map, retryWhen } from 'rxjs/operators';
import { empty } from 'rxjs';


@Injectable({ providedIn: 'root'})
export class AuthServerProvider implements OnDestroy {

    public tokenValue: any;  // deve ser guardado num storage local
    refreshTokenValue: any;
    authEventEmitter: EventEmitter<string>;
    httpInterceptorDisabled: boolean;
    constructor(
        private http: HttpClient,
        private coreMessageService: CoreMessageService,
    ) {
        this.authEventEmitter = new EventEmitter();
    }
    public getAuthEventEmitter() {
        return this.authEventEmitter;
    }
    ngOnDestroy() {
        this.authEventEmitter.unsubscribe();
    }
    getToken() {
        if (this.tokenValue === undefined) {
             // this.tokenValue = this.$localStorage.retrieve('authenticationToken');
        }
        return this.tokenValue;
    }
    getRefreshToken() {
        if (this.refreshTokenValue === undefined) {
            // this.refreshTokenValue = this.$localStorage.retrieve('authenticationTokenRefresh');
        }
        return this.refreshTokenValue;
    }

    login(credentials): Observable<any> {
        //const data =  new URLSearchParams();
        //data.append('grant_type', 'password');
        //data.append('username', credentials.username);
        //data.append('password', credentials.password);
        //data.append('scope', environment.OAUTH_SCOPES);
        
        //let options = {
        //        headers: new HttpHeaders ({
        //            'Content-Type': 'application/x-www-form-urlencoded',
        //            'Authorization' : 'Basic ' + btoa(environment.OAUTH_CLIENT_KEY + ':' + environment.OAUTH_CLIENT_SECRET),
        //        })
        //}; 

        //return this.http.post(environment.URL_WSO2_AUTH, data.toString(), options).pipe(map((response:any) => {
        //    console.log('auth-provider.response: ', response);
        //    const tokenInfo = response;
        //    this.tokenValue = tokenInfo['access_token'];
        //    this.refreshTokenValue = tokenInfo['refresh_token'];
        //    this.authEventEmitter.emit('auth:success');
        //    this.coreMessageService.getGlobalEventEmmiter().emit('auth:success');  // emite mensagem de autenticacao com sucesso
        //    return tokenInfo;
           
        //}));
        return empty();
    }

    refreshToken(): Observable<any> {
        //const data = new URLSearchParams();
        //data.append('grant_type', 'refresh_token');
        //data.append('refresh_token', this.refreshTokenValue);

        //const headers = new HttpHeaders ({
        //    'Content-Type': 'application/x-www-form-urlencoded',
        //    'Authorization' : 'Basic ' + btoa(environment.OAUTH_CLIENT_KEY + ':' + environment.OAUTH_CLIENT_SECRET),
        //});

        //return this.http.post(environment.URL_GATEWAY + '/oauth/token' , data, {
        //    headers,
        //}).pipe( map((response: any) => {
        //    const tokenInfo = response.json();
        //    this.tokenValue = tokenInfo['access_token'];
        //    this.refreshTokenValue = tokenInfo['refresh_token'];
        //    // this.$localStorage.store('authenticationToken', this.tokenValue);
        //    // this.$localStorage.store('authenticationTokenRefresh', this.refreshTokenValue);
        //    // auth:refresh-success
        //    this.coreMessageService.getGlobalEventEmmiter().emit('auth:success');
        //    this.authEventEmitter.emit('auth:success');
        //    return this.tokenValue;
        //}), retryWhen(genericRetryStrategy({
        //    scalingDuration: 2000,
        //    excludedStatusCodes: [500]
        //})));
        return empty();
    }

    verify(credentials): Observable<any> {
        //const data = new URLSearchParams();
        //data.append('grant_type', 'password');
        //data.append('username', credentials.username);
        //data.append('password', credentials.password);

        //const headers = new HttpHeaders ({
        //    'Content-Type': 'application/x-www-form-urlencoded',
        //    'Authorization' : 'Basic ' + btoa(environment.OAUTH_CLIENT_KEY + ':' + environment.OAUTH_CLIENT_SECRET),
        //});

        //return this.http.post(environment.URL_GATEWAY + '/oauth/token', data, {
        //    headers,
        //}).pipe( map((resp: any) => {
        //    const accessToken = resp.json()['access_token'];
        //    return accessToken;
        //}), retryWhen(genericRetryStrategy({
        //    scalingDuration: 2000,
        //    excludedStatusCodes: [500]
        //})));
        return empty();
    }
    loginWithToken(jwt, rememberMe) {
        //if (jwt) {
        //    this.storeAuthenticationToken(jwt, rememberMe);
        //    return Promise.resolve(jwt);
        //} else {
        //    return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        //}
        return Promise.resolve("");
    }
    storeAuthenticationToken(jwt, rememberMe) {
        //this.tokenValue = jwt;
        //console.log('storeAuthenticationToken: ', this.tokenValue);
        //if (rememberMe) {
        //    // this.$localStorage.store('authenticationToken', jwt);
        //} else {
        //    // this.$localStorage.store('authenticationToken', jwt);
        //}
    }
    storeAccountProfiles(profiles) {
        // this.$localStorage.store('accountProfiles', profiles);        
    }
    logout(redirect?: boolean): Observable<any> {
        //console.log('################# doLogout');
        //return new Observable((observer) => {
        //    this.tokenValue = null;
        //    // this.$localStorage.clear('authenticationToken');
        //    this.tokenValue = null;
        //    this.authEventEmitter.emit('auth:logout');
        //    this.coreMessageService.getGlobalEventEmmiter().emit('auth:logout');
        //    console.log('logout!');
        //    // console.log('token: ', this.$localStorage.retrieve('authenticationToken'));
        //    // observer.complete();
        //    observer.complete();
        //});
        return empty();
    }

    public openUrlFrame(url: string): void {
        //window.location.href = `${url}?accessToken=${this.getToken()}&amp;urlPortal=${environment.URL_PORTAL}`;
        window.location.href = `${url}?urlPortal=${environment.URL_PORTAL}`;
    }
}
