import {Contato} from './contatos.model';

export class Pessoa {

    constructor(
        public id?: number,
        public cadastroCompleto?: boolean,
        public contato?: Contato,
        public dataInicio?: Date,
        public dataFim?: Date,
    ) {
    }

    static fromJson(json: any): Pessoa {
        if (json === undefined || json === null) {
            return null;
        }
        return new Pessoa(
            json.id,
            json.cadastroCompleto,
            Contato.fromJson(json.contato),
            json.dataInicio,
            json.dataFim,
        );
    }
}
