import {BaseModel} from '../../../../shared/models/base.model';

export class Contato extends BaseModel {

    static fromJson(json: any): Contato {
        if (json === undefined || json === null) {
            return null;
        }
        return new Contato(
            json.id,
            json.dataInicio,
            json.dataFim,
        );
    }
    constructor(
        public id?: number,
        public dataInicio?: Date,
        public dataFim?: Date,
    ) {
        super();
    }
}
