import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CoreExcludeDialogData {
    id: any;
    label: string; // nome comum
}

@Component({
  selector: 'core-exclude-dialog',
  template: `<h1 mat-dialog-title>Excluir</h1>
    <mat-dialog-content>
      <p>Confirmar a exclusão?</p>
      <div>
         {{ data.label }}
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" class="p-12">
      <button mat-button mat-dialog-close (click)="onNoClick()" >Cancelar</button>
      <button mat-button (click)="confirm()" cdkFocusInitial class="mat-warn">Confirmar</button>
    </mat-dialog-actions>
  `

})
export class CoreExcludeDialogComponent {
  confirmAction = false;
  constructor(
    public dialogRef: MatDialogRef<CoreExcludeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CoreExcludeDialogData) {}
    onNoClick(): void {
      this.dialogRef.close(false);
    }
    confirm() {
      this.dialogRef.close(true);
    }

}
