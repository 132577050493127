import {Injectable, OnDestroy} from '@angular/core';
import {AuthServerProvider} from './auth-provider.service';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';


@Injectable({ providedIn: 'root'})
export class Principal implements OnDestroy {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();
    private authSubscription: any;
    
    constructor(private authProvider: AuthServerProvider) {
    }

    ngOnDestroy(): void {
        if (this.authSubscription != null){
            this.authSubscription.unsubscribe();
        }
    }

    authenticate(identity): void {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        for (let i = 0; i < authorities.length; i++) {
            if (this.userIdentity.authorities.indexOf(authorities[i]) !== -1) {
                return true;
            }
        }

        return false;
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }
}
