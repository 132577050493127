import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-dialog-confirmacao',
    templateUrl: './dialog-confirmacao-receita-natureza.component.html',
    styleUrls: ['./dialog-confirmacao-receita-natureza.component.scss']
})
export class DialogConfirmacaoReceitaNaturezaComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<DialogConfirmacaoReceitaNaturezaComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
    }

    cancelar(): void {
        this.dialogRef.close(false);
    }
}
