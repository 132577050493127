import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ReemissaoTaxa} from '../../../shared/models/reemissao-taxa.model';
import {ReemissaoTaxaService} from '../../../services/reemissao-de-taxa.service';
import {Utils} from '../../../util/utils';
import {fuseAnimations} from '@fuse/animations';
import {MensagensUsuario} from '../../../util/mensagens-usuario';
import {ScorpApiService} from '../../../shared/services/scorp-api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RedirectUtil} from "../../../util/RedirectUtil";

@Component({
    selector: 'app-reemissao-de-taxa',
    templateUrl: './reemissao-de-taxa.component.html',
    styleUrls: ['./reemissao-de-taxa.component.scss'],
    animations: fuseAnimations
})
export class ReemissaoTaxaComponent implements OnInit {

    cpfCnpj: any;
    reemissaoTaxa: ReemissaoTaxa[] = [];
    displayedColumns = ['numeroDar', 'cnaeDescricao', 'quantidadeUpf', 'origem', 'situacao', 'acao'];
    displayCpfCnpj: string[] = ['cpfcnpj', 'nomerazaosocial'];
    requerente: any = [];
    protected router: Router;
    protected activatedRoute: ActivatedRoute;

    constructor(protected snackBar: MatSnackBar,
                public utils: Utils,
                public reemissaoTaxaService: ReemissaoTaxaService,
                private scorpApiService: ScorpApiService) {

    }

    ngOnInit() {

    }

    isCpf(): boolean {
        return this.cpfCnpj == null ? true : this.cpfCnpj.length < 12 ? true : false;
    }

    getCpfCnpj(): string {
        return this.isCpf() ? '000.000.000-009' : '00.000.000/0000-00';
    }

    voltar(): void {
        this.router
            .navigateByUrl(this.getBaseComponetPath(), {skipLocationChange: true})
            .then(() => this.router.navigate([this.getBaseComponetPath()], {skipLocationChange: true}));
    }

    protected getBaseComponetPath(): string {
        return this.activatedRoute.snapshot.parent.url[0].path;
    }

    pesquisar(): void {
        this.reemissaoTaxa = [];
        if (!this.cpfCnpj || this.cpfCnpj === '') {
            this.utils.showSnackBarWarning(MensagensUsuario.CPF_CNPJ_VAZIO);
            return;
        }

        if (this.isCpf()) {
            if (!Utils.isCpfValido(this.cpfCnpj)) {
                this.utils.showSnackBarWarning(MensagensUsuario.CPF_CNPJ_INVALIDO);
            } else {
                this.consultarPessoaFisicaCpf();
            }
        } else {
            if (!Utils.isCnpjValido(this.cpfCnpj)) {
                this.utils.showSnackBarWarning(MensagensUsuario.CPF_CNPJ_INVALIDO);
            } else {
                this.consultarPessoaJuridicaCnpj();

            }
        }
    }

    emitirNovaDar(id: any) {
        this.reemissaoTaxaService
            .emitirNovaDar(id)
            .subscribe((sucesso) => {
                this.obterTaxasPorCpfCnpj(this.cpfCnpj);
            }, (erro) => {
                this.utils.showSnackBarError(MensagensUsuario.ERRO_REEMISSAO_DAR);
            });

    }

    obterTaxasPorCpfCnpj(cpfCnpj: string): void {
        this.reemissaoTaxaService
            .obterTaxasPorCpfCnpj(cpfCnpj)
            .subscribe(taxas => {
                this.reemissaoTaxa = taxas;
            });
    }

    public downloadDar(idDar): void {
        window.open(
            `${this.reemissaoTaxaService.urlResource}/${idDar}/download`
        );
    }

    consultarPessoaJuridicaCnpj() {
        this.scorpApiService.getByPessoaJuridica(this.cpfCnpj.toString()).subscribe(pesssoaJuridica => {
            const pessoa = [];
            pessoa.push(pesssoaJuridica);
            this.requerente = pessoa;
            this.obterTaxasPorCpfCnpj(this.cpfCnpj);
        }, () => this.utils.showSnackBarWarning(MensagensUsuario.CPF_CNPJ_NAO_CADASTRADO_NO_SISTEMA));
    }

    consultarPessoaFisicaCpf() {
        this.scorpApiService.getByPessoaFisica(this.cpfCnpj.toString()).subscribe(pessoaFisica => {
            const pessoa = [];
            pessoa.push(pessoaFisica);
            this.requerente = pessoa;
            this.obterTaxasPorCpfCnpj(this.cpfCnpj);
        }, () => this.utils.showSnackBarWarning(MensagensUsuario.CPF_CNPJ_NAO_CADASTRADO_NO_SISTEMA));
    }

    fechar() {
        RedirectUtil.redirecionaCaixaEntrada();
    }
}
