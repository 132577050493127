import {NgModule} from '@angular/core';
import {LoadingComponent} from './loading.component';
import {NgxLoadingModule} from "ngx-loading";

@NgModule({
    declarations: [LoadingComponent],
    imports: [NgxLoadingModule],
    exports: [LoadingComponent],
    entryComponents: [LoadingComponent]
})
export class LoadingModule {
}
