import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Observable } from 'rxjs';
import {AudioPlayer} from "../audio-player/audio-player.";
import {MessageDialogComponent} from './message-dialog.component';

function asMessage(message: any) {
    if ((<IMessageObject>message).message) {
        return message.message;
    }

    return `${message}`;
}

function asErrors(message: any) {
    if ((<IErrorMessageObject>message).errors) {
        return message.errors && message.errors.length ? message.errors : [];
    }

    return [];
}

export interface IMessageObject {
    message: string;
}

export interface IErrorMessageObject extends IMessageObject {
    errors: Array<string>;
}

export enum MessageDialogIcon {
    Information = 'info',
    Question = 'contact_support',
    Warning = 'warning',
    Error = 'cancel'
}

export enum MessageDialogButtons {
    OK = 1,
    Cancel = 2,
    Yes = 4,
    No = 8
}

export enum MessageDialogResult {
    None = 0,
    OK,
    Cancel,
    Yes,
    No
}

@Injectable({ providedIn: 'root' })
export class MessageDialog {
    constructor(
        private dialog: MatDialog,
        private audioPlayer: AudioPlayer) {

    }

    show(message: string | IMessageObject | IErrorMessageObject,
        title: string = '',
        icon: MessageDialogIcon = MessageDialogIcon.Information,
        buttons: MessageDialogButtons = MessageDialogButtons.OK): Observable<MessageDialogResult> {

        const config = new MatDialogConfig();

        config.disableClose = true;
        config.data = {            
            title: title,
            icon: icon,
            buttons: buttons,
            message: asMessage(message),
            errors: asErrors(message),
        };

        return this.dialog.open(MessageDialogComponent, config).afterClosed();
    }

    showInformation(message: string | IMessageObject, title: string = '') {
        return this.show(message, title, MessageDialogIcon.Information, MessageDialogButtons.OK);
    }

    showQuestion(message: string | IMessageObject, title: string = ''): Observable<MessageDialogResult> {
        return this.show(message, title, MessageDialogIcon.Question, MessageDialogButtons.Yes | MessageDialogButtons.No);
    }

    showWarning(message: string | IMessageObject | IErrorMessageObject, title: string = ''): Observable<MessageDialogResult> {
        return this.show(message, title, MessageDialogIcon.Warning, MessageDialogButtons.OK);
    }

    showError(message: string | IMessageObject | IErrorMessageObject, title: string = ''): Observable<MessageDialogResult> {
        this.audioPlayer.play('error');

        return this.show(message, title, MessageDialogIcon.Error, MessageDialogButtons.OK);
    }
}