import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {Error401Component} from './401/error-401.component';
import {Error404Component} from './404/error-404.component';
import {Error500Component} from './500/error-500.component';
import {PageErrorComponent} from './page-error.component';

const routes = [
    {
        path: 'error',
        component: PageErrorComponent,
    },
    {
        path: 'error/error-401',
        component: Error401Component,
    },
    {
        path: 'error/error-404',
        component: Error404Component,
    },
    {
        path: 'error/error-500',
        component: Error500Component,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes), MatIconModule],
    entryComponents: [Error401Component, Error404Component, Error500Component],
    declarations: [PageErrorComponent, Error401Component, Error404Component, Error500Component],
    exports: [PageErrorComponent, Error401Component, Error404Component, Error500Component],
})
export class PageErrorsModule { }
