import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {LoadingService} from './loading.service';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    constructor(private loadingService: LoadingService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingService.setValue(true);
        return next.handle(req).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    this.loadingService.setValue(false);
                }
                return event;
            }), catchError(err => {
                this.loadingService.setValue(false);
                return throwError(err);
            })
        );
    }
}
