import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {

    private loadingSubject = new BehaviorSubject(false);

    get value(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    public setValue(value: boolean): void {
        this.loadingSubject.next(value);
    }

}
