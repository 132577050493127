import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import * as jwt_decode from 'jwt-decode'
import {environment} from '../../environments/environment';

export interface IdentityTokenDetails {
    servidor: boolean;
    nome: string;
    gruposCamunda: Array<string>
}

export class IdentityToken {

    public static decode(jwt: string): IdentityToken {
        if (jwt && jwt.length) {
            const other = jwt_decode(jwt);
            const instance = new IdentityToken(
                other.sub,
                other.iss,
                new Date(other.iat),
                new Date(other.exp),
                other.details
            );

            return instance;
        }

        return null;
    }
    constructor(
        public sub?: string,
        public issuer?: string,
        public isuedAt?: Date,
        public expiresAt?: Date,
        public details?: IdentityTokenDetails
    ) {
    }
}

@Injectable()
export class AuthenticatedUserService {

    constructor(
        private cookiesService: CookieService
    ) {

    }

    public get isAuthenticated(): boolean {
        const authorization: string = this.cookiesService.get(environment.IDENTITY_COOKIE_NAME);
       return authorization && authorization.length > 0;
    }

    public get identityTokenValue(): string {
        return this.isAuthenticated
            ? this.cookiesService.get(environment.IDENTITY_COOKIE_NAME)
            : '';
    }

    public get identityToken(): IdentityToken {
        const jwt = this.cookiesService.get(environment.IDENTITY_COOKIE_NAME);
        console.log('jwt', jwt);
        if (jwt)
            return IdentityToken.decode(jwt);
        return null;
    }
}
