import { NgModule } from '@angular/core';
import {VerticalLayout1Module} from "./vertical/layout-1/layout-1.module";
import {CoreComponentsModule} from "../components/core-components.module";


@NgModule({
    imports: [
        CoreComponentsModule,
        VerticalLayout1Module,

    ],
    exports: [
        VerticalLayout1Module,
    ]
})
export class LayoutModule
{
}
