export class MensagensUsuario {
    public static CPF_CNPJ_INVALIDO = 'CPF/CNPJ inválido.';
    public static CPF_CNPJ_VAZIO = 'Favor inserir um CPF/CNPJ.';
    public static CPF_CNPJ_NULL = 'Para avançar é necessário selecionar um interessado.';
    public static CPF_CNPJ_NAO_ENCONTRADO = 'Usuário não cadastrado no sistema, favor efetuar seu cadastro no sistema SIGA. (Dúvidas sobre o cadastro, solicitar pelo email atendimento@sema.mt.gov.br, ou telefones: 3645-4914, 4915 e 4916.)';
    public static NUM_PROCESSO_NAO_INSERIDO = 'Não foi possível inserir o número do processo.';
    public static NUM_PROCESSO_INSERIDO_SUCESSO = 'O número de processo foi associado ao DAR.';
    public static CPF_CNPJ_NAO_CADASTRADO_NO_SISTEMA = 'Usuário não cadastrado no sistema.';
    public static ERRO_REEMISSAO_DAR = 'Erro na emissão da DAR.';
    public static NOME_PESSOA_NAO_ENCONTRADO = 'Pessoa não encontrada.';
    public static PREENCHER_DADOS = 'É necessário preencher o CPF/CNPJ ou CPF Emissor';
    public static NAO_ENCONTRADO_DARS_COM_OS_DADOS = 'Não foram encontrados Registros com as opções escolhidas.';
    public static PREENCHIMENTO_OBRIGATORIO_VENCIMENTO = 'Campo Vencimento é de preenchimento obrigatório';
    public static DATA_INVALIDA = 'Data inválida. Favor informar uma data válida';
    public static DATA_INICIAL_MENOR_FINAL = 'A data final não pode ser anterior a data inicial';
    public static NAO_ENCONTRADO_LANCAMENTOS = 'Não foram encontrados lançamentos para esse usuário.';
    public static ERRO_AO_VERIFICAR_SERVIDOR = 'Erro ao verificar se o CPF passado é de um servidor.';



}
