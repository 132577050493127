import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CoreModule} from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';
import { ReemissaoTaxaComponent } from './reemissao-de-taxa.component';
import {SharedModule} from '../../../shared/shared.module';
import {CommonModule} from '@angular/common';

export const routes: Routes = [
    {
        path: '',
        component: ReemissaoTaxaComponent
    },
];

@NgModule({
  declarations: [
      ReemissaoTaxaComponent,
  ],
    imports: [
        CoreModule,
        LayoutModule,
        RouterModule.forChild(routes),
        SharedModule,
        CommonModule
    ],
  entryComponents: [
  ],
  providers: [
  ]
})
export class ReemissaoTaxaModule { }
