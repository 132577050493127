import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import Pt from '@angular/common/locales/pt';
import {LOCALE_ID, NgModule} from '@angular/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {FuseProgressBarModule} from '@fuse/components';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';
import {CookieService} from 'ngx-cookie-service';
import {NgxLoadingModule} from 'ngx-loading';
import {environment} from '../environments/environment';
import {PagesModule} from './main/pages/pages.module';
import {AppArrecadacaoComponent} from "./app.arrecadacao.component";
import {AuthenticationInterceptor} from "./main/core/interceptors/authentication.interceptor";
import {ErrorInterceptor} from "./main/core/interceptors/error.interceptor";
import {RoutingStateService} from "./services/routing-state.service";
import {LoadingInterceptor} from "./main/core/components/loading/loading.interceptor";
import {PageErrorsModule} from "./main/core/layout/errors/page-errors.module";
import {LoadingModule} from "./main/core/components/loading/loading.module";
import {LayoutModule} from "./main/core/layout/layout.module";
import {fuseConfig} from "./main/core/fuse-config";
import {LanguageHelper} from "./services/language-helper.service";
import {AuthenticatedUserService} from "./services/authenticated-user.service";
import {UtilService} from "./services/util.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CoreModule} from "./main/core/core.module";

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: './main/pages/pages.module#PagesModule',
    },
];

registerLocaleData(Pt);

@NgModule({
    declarations: [AppArrecadacaoComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CoreModule.forRoot(),
        RouterModule.forRoot(appRoutes, {
            useHash: true,
            enableTracing: false,
        }),
        NgxLoadingModule.forRoot({}),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseProgressBarModule,

        LoadingModule,

        // App modules
        LayoutModule,
        PageErrorsModule,
        PagesModule,
    ],
    exports: [PagesModule],
    providers: [
        RoutingStateService,
        AuthenticatedUserService,
        UtilService,
        LanguageHelper,
        CookieService,
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
        {provide: LOCALE_ID, useValue: 'pt'}
    ],
    bootstrap: [AppArrecadacaoComponent],
})
export class AppModule {
    constructor() {
        console.log('[arrecadacao] ambiente de ' + environment.name); 
    }
}
