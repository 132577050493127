import {BaseModel} from './base.model';

export class ReemissaoTaxa  extends BaseModel {

    constructor(
        public id?: number,
        public cnaeDescricao?: string,
        public quantidadeUpf?: string,
        public situacao?: string,
        public numeroDar?: number
    ) {
        super();
    }

    static fromJson(json: any): ReemissaoTaxa {
        if (json === undefined || json === null) {
            return null;
        }
        return new ReemissaoTaxa(
            json.id,
            json.cnaeDescricao,
            json.quantidadeUpf,
            json.situacao,
            json.numeroDar,
        );
    }


}
