import {Injectable, Injector} from '@angular/core';
import {BaseService} from '../shared/services/base.service';
import {ReemissaoTaxa} from '../shared/models/reemissao-taxa.model';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReemissaoTaxaService  extends BaseService<ReemissaoTaxa> {

    constructor(protected injector: Injector) {
        super('/api/reemissaoTaxa', injector, ReemissaoTaxa.prototype, ReemissaoTaxa.fromJson);
    }

    obterTaxasPorCpfCnpj(cpfCnpj: string): Observable<ReemissaoTaxa[]> {
        return this.http.get(`${this.urlResource}/${cpfCnpj}`).pipe(
            map(response => this.jsonToResources(response)),
            catchError(this.handleError.bind(this))
        );

    }

    emitirNovaDar(id: string): Observable<Object[]> {
        return this.http.get(`${this.urlResource}/gerarNovaDar/${id}`).pipe(
            catchError(this.handleError.bind(this))
        );

    }




}
