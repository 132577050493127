import {MatSnackBar} from "@angular/material/snack-bar";
import {Injectable} from "@angular/core";
import Big from 'big.js';
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class Utils {

    constructor(public snackBar: MatSnackBar) {
    }

    public showSnackBarWarning(message: string, duration: number = 3000, position: any = 'top'): void {
        this.snackBar.open(message, 'ATENÇÃO', {
            duration: duration,
            verticalPosition: position,
            panelClass: ['warning-snackbar'],
        });
    }

    public showSnackBarError(message: string, duration: number = 3000, position: any = 'top'): void {
        this.snackBar.open(message, 'Erro', {
            duration: duration,
            verticalPosition: position,
            panelClass: ['error-snackbar'],
        });
    }

    public showSnackBarSucess(message: string, duration: number = 3000, position: any = 'top'): void {
        this.snackBar.open(message, 'SUCESSO', {
            duration: duration,
            verticalPosition: position,
            panelClass: ['sucess-snackbar'],
        });
    }

    // TODO Verifica de CPF é válido
    static isCpfValido(cpf: string): boolean {
        const cpfLength = 11;
        const weights = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
        return this.isValido(cpf, cpfLength, weights);
    }


    // TODO Verifica de CNPJ é válido
    static isCnpjValido(cnpj: string): boolean {
        const cpfLength = 14;
        const weights = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5, 6];
        return this.isValido(cnpj, cpfLength, weights);
    }

    getCpfCnpj(cpf_cnpj: string): string {
        return this.isCpf(cpf_cnpj) ? '000.000.000-009' : '00.000.000/0000-00';
    }

    isCpf(cpf_cnpj: string): boolean {
        return cpf_cnpj == null ? true : cpf_cnpj.length < 12 ? true : false;
    }

    cpfCnpjMaskParaTabela(cpfOrCnpj: string): string {
        const cnpjCpf = cpfOrCnpj.replace(/\D/g, '');

        if (cnpjCpf.length === 11) {
            return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3-\$4');
        } else {
            return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3/\$4-\$5');
        }
    }

    static isValido(digits: string, correctDigitsLength: number, weights: number[]): boolean {
        const cleanDigits = this.obterApenaNumeros(digits);
        if (cleanDigits.length !== correctDigitsLength || this.isMesmoDigitos(cleanDigits)) {
            return false;
        }
        const digitsWithoutChecker = cleanDigits.substring(0, correctDigitsLength - 2);
        const digitsChecker = cleanDigits.substring(correctDigitsLength - 2, correctDigitsLength);
        const calculetedChecker = this.calcular(digitsWithoutChecker, weights);
        return digitsChecker === calculetedChecker;
    }

    static obterApenaNumeros(digits: string): string {
        return digits.replace(/\D/g, '');
    }

    static isMesmoDigitos(digits: string): boolean {
        return !digits.split('').some((digit) => digit !== digits[0]);
    }

    static calcular(digits: string, weights: number[]): string {
        const digitsLength = digits.length;
        const digitsLengthWithoutChecker = weights.length - 1;

        const sum = digits.split('').reduce((acc, digit, idx) => {
            return acc + +digit * weights[digitsLength - 1 - idx];
        }, 0);
        const sumDivisionRemainder = sum % 11;
        const checker = sumDivisionRemainder < 2 ? 0 : 11 - sumDivisionRemainder;

        if (digitsLength === digitsLengthWithoutChecker) {
            return this.calcular(`${digits}${checker}`, weights);
        }

        return `${digits[digitsLength - 1]}${checker}`;
    }

    stringToBigDecimal(numero) {
        numero = "" + numero;
        numero = numero.trim();
        return parseFloat(numero).toFixed(2);
    }

    // ex de data: 2020-10-15T20:58:45.577-03:00
    static zonedDateTimeStringFormatter(zonedDateTimeString: string): string {
        if (!zonedDateTimeString || zonedDateTimeString.length < 8) {
            return '';
        }
        return zonedDateTimeString.substr(8, 2) + '/' +
            zonedDateTimeString.substr(5, 2) + '/' +
            zonedDateTimeString.substr(0, 4) + ' ' +
            zonedDateTimeString.substr(11, 5)
    }

    createRequestOptions = (req?: any): HttpParams => {
        let options: HttpParams = new HttpParams();
        if (req) {
            Object.keys(req).forEach((key) => {
                if (key !== 'sort') {
                    options = options.set(key, req[key]);
                }
            });
            if (req.sort) {
                req.sort.forEach((val) => {
                    options = options.append('sort', val);
                });
            }
        }
        return options;
    };

    formataValorBrl(preco: number) {
        return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(preco);
    }

    formataValor(preco: number) {
        return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(preco).replace('R$', '');
    }

    //https://stackoverflow.com/questions/6177975/how-to-validate-date-with-format-mm-dd-yyyy-in-javascript/49178339
    // Validates that the input string is a valid date formatted as "dd/mm/yyyy"
    isValidDate(dateString: string) {
        // First check for the pattern
        if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
            return false;

        // Parse the date parts to integers
        let parts = dateString.split("/");
        let day = parseInt(parts[0], 10);
        let month = parseInt(parts[1], 10);
        let year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;

        let monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }


    removeAcento(text: string) {
        return text.normalize("NFD").replace(/[^a-zA-Zs]/g, "");
    }
}
