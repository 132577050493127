import { Component, ViewEncapsulation } from '@angular/core';

export interface IPageError {
    code?: number, message?: string, link?: string
}

@Component({
    selector: 'app-page-error',
    templateUrl: './page-error.component.html',
    styleUrls: ['./error.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageErrorComponent {
    error: IPageError;
    /**
     * Constructor
     */
    constructor() {
        this.error = {};
    }
}
