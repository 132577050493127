import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {PessoaFisica} from "../models/pessoa-fisica.model";
import {PessoaJuridica} from "../models/pessoa-juridica.model";
import {BaseService} from "./base.service";
import {environment} from "../../../environments/environment";
import {PessoaEnderecoTelefoneDTO} from "../models/pessoa-endereco-telefone-dto.model";

@Injectable({
    providedIn: 'root'
})
export class ScorpApiService extends BaseService<PessoaFisica>{
    private urlResourceScrop = environment.URL_SCORP_API;

    constructor(protected injector: Injector) {
        super('scorp', injector, PessoaFisica.prototype, PessoaFisica.fromJson);
    }

    getByPessoaFisica(cpf: string): Observable<PessoaFisica> {
        const url = `${this.urlResourceScrop}/api/pessoas-fisicas/cpf/${cpf}`;
        return this.http.get(url).pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getByPessoaJuridica(cnpj: string): Observable<PessoaJuridica> {
        const url = `${this.urlResourceScrop}/api/pessoas-juridicas/cnpj/${cnpj}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    getPessoaFisicaEmissaoTaxa(cpf: string): Observable<PessoaEnderecoTelefoneDTO> {
        const url = `${this.urlResourceScrop}/api/pessoas-fisicas/buscar-pessoa-por-cpf/${cpf}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getPessoaJuridicaEmissaoTaxa(cnpj: string): Observable<PessoaEnderecoTelefoneDTO> {
        const url = `${this.urlResourceScrop}/api/pessoas-juridicas/buscar-pessoa-por-cnpj/${cnpj}`;
        return this.http
            .get(url)
            .pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    isServidorPessoaFisica(cpf: string): Observable<any> {
        const url = `${this.urlResourceScrop}/api/pessoas-fisicas/is-servidor/${cpf}`;
        return this.http.get(url).pipe(
                map(response => response),
                catchError(this.handleError.bind(this))
            );
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}
