import {Component, OnDestroy} from '@angular/core';
import {LoadingService} from './loading.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-loading',
    template: '<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: \'3px\', fullScreenBackdrop: true }"></ngx-loading>'
})
export class LoadingComponent implements OnDestroy {

    loading = false;

    private _unsubscribeAll = new Subject();

    constructor(loadingService: LoadingService) {
        loadingService.value.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => this.loading = value);
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}
