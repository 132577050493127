import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {LanguageHelper} from "./language-helper.service";

@Injectable()
export class UtilService {

    constructor(private languageHelper: LanguageHelper) {
    }

    public static getGuid(): string {
        return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public static  arquivoExtensao(fileName) {
        if (fileName != null) {
            return fileName.split('.').pop();
        }
        return '';
    }

    strToZonedDateTime(date: string, fromFormat: string, lang?: any) {
        if (date === undefined || date === null)
            return null;

        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data = moment(date, fromFormat, true);
        const formatDate =  data.format('YYYY-MM-DDTHH:mm:ss.[001][Z]'); // data.toISOString();
        // let formatDate = data.utc().format();
        return formatDate;
    }
    zonedDateTimeFormat(date: string, format: string, lang?: any) {
        if (date === undefined || date === null)
            return null;
        if (!lang)
            lang = this.languageHelper.getService().getDefaultLang();
        const data = moment.parseZone(date);
        const formatDate = data.format(format);
        return formatDate;
    }

    toDataUrl(url, callback) {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
            const reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    /**
     * ARAYS
     */
    findObjectByKey(array, key, value) {
        if (array !== null) {
            for (let i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return array[i];
                }
            }
        }
        return null;
    }

}
