import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import {VerticalLayout1Component} from "./layout-1.component";
import {ContentModule} from "../../components/content/content.module";
import {FooterModule} from "../../components/footer/footer.module";
import {NavbarModule} from "../../components/navbar/navbar.module";
import {ToolbarModule} from "../../components/toolbar/toolbar.module";


@NgModule({
    declarations: [
        VerticalLayout1Component
    ],
    imports     : [
        RouterModule,

        FuseSharedModule,
        FuseSidebarModule,
        ContentModule,
        FooterModule,
        NavbarModule,
        ToolbarModule 
        
    ],
    exports : [
        VerticalLayout1Component
    ]
})
export class VerticalLayout1Module
{
}
