import {BaseModel} from "./base.model";
import {Pessoa} from "../../main/core/components/models/pessoa.model";

export class PessoaFisica extends BaseModel {

    constructor(
        public id?: number,
        public cpf?: string,
        public nome?: string,
        public nomeSocial?: string,
        public dataNascimento?: Date,
        public sexo?: string,
        public estadoCivil?: string,
        public pessoa?: Pessoa,
        public rg?: string,
        public rgOrgaoEmissor?: string,
        public rgUfEmitente?: string,
        public naturalidade?: string,
        public pai?: string,
        public mae?: string,
        public dataInicio?: Date,
        public dataAtualizacao?: Date,
        public migrado?: boolean,
    ) {
        super();
    }

    static fromJson(json: any): PessoaFisica {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaFisica(
            json.id,
            json.cpf,
            json.nome,
            json.nomeSocial,
            json.dataNascimento,
            json.sexo,
            json.estadoCivil,
            Pessoa.fromJson(json.pessoa),
            json.rg,
            json.rgOrgaoEmissor,
            json.rgUfEmitente,
            json.naturalidade,
            json.pai,
            json.mae,
            json.dataInicio,
            json.dataAtualizacao,
            json.migrado,
        );
    }
}
