import {Injectable, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {LangChangeEvent, TranslateService, TranslationChangeEvent} from '@ngx-translate/core';
import {LANGUAGES} from 'app/shared/models/language.constants';

@Injectable()
export class LanguageHelper implements OnDestroy {

    private transactionChangeSubscription: any
    private langChangeSubscription: any;

    constructor( private titleService: Title,  private ts: TranslateService ) {
        this.init();
    }

    ngOnDestroy() {
        if (this.transactionChangeSubscription != null) {
            this.transactionChangeSubscription.unsubscribe();
        }
        if (this.langChangeSubscription != null) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    getService(): TranslateService {
        return this.ts;
    }

    public getDefaultLang(): string {
        return this.ts.getDefaultLang();
    }

    getAll(): Promise<any> {
        return Promise.resolve(LANGUAGES);
    }

    /**
     * Update the window title using params in the following
     * order:
     * 1. titleKey parameter
     * 2. $state.$current.data.pageTitle (current state page title)
     * 3. 'global.title'
     */
    updateTitle(titleKey?: string) {
        if (!titleKey) {
            // titleKey = this.getPageTitle(this.router.routerState.snapshot.root);
        }

        this.ts.get(titleKey).subscribe((title) => {
            this.titleService.setTitle(title);
        });
    }

    private init() {
        this.transactionChangeSubscription = this.ts.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
            this.updateTitle();
        });

        this.langChangeSubscription =  this.ts.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateTitle();
        });
    }
}
