import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {FuseDirectivesModule} from "../../@fuse/directives/directives";
import {PdfViewerDialogComponent} from "./pdf-viewer-dialog/pdf-viewer-dialog.component";
import {MessageDialogComponent} from "./message-dialog/message-dialog.component";
import {NgxExtendedPdfViewerModule} from "ngx-extended-pdf-viewer";

@NgModule({
    declarations: [
        MessageDialogComponent,
        PdfViewerDialogComponent
    ],
    imports: [
        CommonModule,
        RouterModule,

        // Material Modules
        MatStepperModule,
        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatListModule,
        MatTabsModule,
        MatDatepickerModule,
        MatRadioModule,
        MatAutocompleteModule,
        FlexLayoutModule,
        NgxMaskModule.forRoot({
            validation: true,
        }),
        FormsModule,
        ReactiveFormsModule,
        FuseDirectivesModule,
        MatTooltipModule,
        MatSlideToggleModule,
        NgxExtendedPdfViewerModule
    ],
    exports: [
        RouterModule,
        MatStepperModule,
        MatSelectModule,
        MatMenuModule,
        MatIconModule,
        NgxMaskModule,
        MatDialogModule,
        MatCardModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatListModule,
        MatTabsModule,
        MatDatepickerModule,
        MatRadioModule,
        MatAutocompleteModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        FuseDirectivesModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatFormFieldModule,
        MatInputModule,
    ],
    entryComponents: [
        MessageDialogComponent,
        PdfViewerDialogComponent
    ],
    providers: [],
})
export class SharedModule {
}
